import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../../components/Layout/index";
import "./blogDetails.scss";

const BlogDetails = ({ location }) => {
  const [filteredData, setFilteredData] = useState();
  const params = new URLSearchParams(location.search);
  const id = parseInt(params.get("id"), 10);

  const data = useStaticQuery(graphql`
    query GetBlogPosts($id: Int) {
      allContentfulBlogs(filter: { blogId: { eq: $id } }) {
        edges {
          node {
            title
            blogId
            bannerImage {
              gatsbyImageData
            }
            blogContent {
              raw
            }
            authorName
            autherDesignation
            autherImage {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (data) {
      const fData = data.allContentfulBlogs.edges.find(
        (item) => item.node.blogId === id
      );
      setFilteredData(fData.node);
    }
  }, [data]);

  return (
    <Layout>
      <div className="blogDetailsContainer">
        {filteredData && (
          <div className="blogDetails">
            <div className="blogDetails__img">
              <img
                src={
                  filteredData.bannerImage.gatsbyImageData.images.fallback.src
                }
                alt=""
              />
              {/* <img src="./../static/blogs/blog1.png" alt="" /> */}
            </div>

            <div className="blogDetails__content">
              <div className="blogDetails__content__title">
                <h2>{filteredData.title}</h2>
                {/* <h2>Building a recruiting chatbot in hrSense</h2> */}
              </div>

              <div className="author">
                <div className="author__img">
                  <img
                    src={
                      filteredData.autherImage.gatsbyImageData.images.fallback
                        .src
                    }
                    alt=""
                  />
                  {/* <img src="./../static/blogs/image.png" alt="" /> */}
                </div>

                <div className="author__details">
                  <h5>{filteredData.authorName}</h5>
                  <p>{filteredData.autherDesignation}</p>
                </div>
              </div>

              <div>
                {JSON.parse(filteredData.blogContent.raw)["content"].map(
                  (item, index) => (
                    <div key={index}>
                      {item.content[0] && item.nodeType === "paragraph" && (
                        <p>{item.content[0].value}</p>
                      )}
                      {item.content[0] && item.nodeType === "heading-2" && (
                        <h2>{item.content[0].value}</h2>
                      )}
                      {item.content[0] && item.nodeType === "unordered-list" && (
                        <ul>
                          {item.content.map((list, index1) => (
                            <li key={index1}>
                              {list.content[0].content[0].value}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default BlogDetails;
